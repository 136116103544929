.base-input {
  justify-content: center;
  align-items: center;
  gap: 28px;
  width: 260px;
  height: 60.2px;
  display: flex;
  position: relative;
}

.base-input .label {
  align-items: center;
  gap: 8px;
  width: 192px;
  display: flex;
  position: relative;
}

.base-input .icon-instance-node-2 {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.base-input .div {
  flex: 1;
  align-items: center;
  gap: 1px;
  display: flex;
  position: relative;
}

.base-input .placeholder-wrapper {
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.base-input .placeholder {
  color: var(--texttext-200);
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.base-input .typer-wrapper {
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.base-input .typer {
  background-color: var(--texttext-200);
  width: 1px;
  height: 16px;
  position: relative;
}

.base-input .frame-2 {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: flex;
  position: relative;
}

.input-field {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 300px;
  display: flex;
  position: relative;
}

.input-field .text-wrapper {
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-style: var(--body-font-style);
  font-weight: var(--body-font-weight);
  letter-spacing: var(--body-letter-spacing);
  line-height: var(--body-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.input-field .frame-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.input-field .base-input-instance {
  border: 0;
  outline: 0;
  padding: 30.1px 0;
  flex: unset !important;
  flex-grow: unset !important;
  width: 100% !important;
  height: 100% !important;
}

.input-field .base-input-instance.error {
  color: #d8000c;
}

.input-field .base-input-instance.success {
  color: #4f8a10;
}

.input-field .note {
  color: var(--texttext-200);
  font-family: var(--caption-font-family);
  font-size: var(--caption-font-size);
  font-style: var(--caption-font-style);
  font-weight: var(--caption-font-weight);
  letter-spacing: var(--caption-letter-spacing);
  line-height: var(--caption-line-height);
  align-self: stretch;
  position: relative;
}

.input-field .active, .input-field .disabled, .input-field .hover, .input-field .alert {
  color: var(--texttext-500);
}

.input-field .normal {
  color: var(--darkdark-200);
}

.input-field .warning, .input-field .success {
  color: var(--texttext-500);
}

.base-button {
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 24px;
  display: inline-flex;
  position: relative;
}

.base-button .icon-instance-node {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.base-button .button-text {
  color: var(--whitewhite-200);
  font-family: var(--body-bold-font-family);
  font-size: var(--body-bold-font-size);
  font-style: var(--body-bold-font-style);
  font-weight: var(--body-bold-font-weight);
  letter-spacing: var(--body-bold-letter-spacing);
  line-height: var(--body-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.type-primary-state {
  background-color: var(--button-primary);
  border: 1px solid var(--button-primary);
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.type-primary-state.outline {
  color: var(--button-primary);
  background-color: unset;
  align-items: center;
}

.type-primary-state.outline .button-text {
  color: var(--button-primary) !important;
}

.type-primary-state .base-button-instance {
  flex: none !important;
}

.type-primary-state.disabled {
  background-color: var(--darkdark-200) !important;
  border-color: var(--darkdark-200) !important;
  cursor: not-allowed !important;
}

.frame {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-width: 375px;
  height: 100%;
  min-height: 509px;
  display: inline-flex;
  position: relative;
}

.frame .group {
  background-color: #fff;
  width: 375px;
  height: 509px;
  position: relative;
  box-shadow: 4px 0 14px #00000040;
}

.frame .line {
  width: 98px;
  height: 7px;
  position: absolute;
  top: 17px;
  left: 138px;
}

.frame .group-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  width: 100%;
  height: 100%;
  padding: 20px 10px;
  display: flex;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.frame .group-2 {
  width: 325px;
  height: 399px;
  position: relative;
}

.frame .button {
  width: 325px !important;
  padding: 14px 100px !important;
  display: flex !important;
}

.frame .type-primary-state-normal-outline-false-link-false {
  font-family: Poppins, Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.frame .div-2 {
  flex-direction: column;
  align-items: center;
  gap: 28px;
  display: inline-flex;
}

.frame .input-field-instance {
  flex: none !important;
  width: 325px !important;
}

.frame .design-component-instance-node {
  align-self: unset !important;
  color: var(--darkdark-500) !important;
  font-family: Poppins, Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  letter-spacing: 0 !important;
  white-space: nowrap !important;
  width: fit-content !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.frame .input-field-2 {
  background-color: var(--whitewhite-200) !important;
  align-self: stretch !important;
  justify-content: unset !important;
  border: 1px solid #b2b2b2 !important;
  border-radius: 4px !important;
  flex: none !important;
  width: 100% !important;
  padding: 20px !important;
  overflow: hidden !important;
}

.frame .input-field-3 {
  color: #b2b2b2 !important;
  font-family: Poppins, Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
  line-height: 19.2px !important;
}

.frame .div-3 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 28px;
  display: inline-flex;
  position: relative;
}

.frame .input-field-4 {
  margin-right: -18px !important;
}

.overlay {
  z-index: 999;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all ease-in-out;
  display: flex;
  position: absolute;
}

.loader {
  box-sizing: border-box;
  border: 7.5px solid #09132d26;
  border-color: #09132d26 #09132d40 #09132d59 #09132d80;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: 1s linear infinite rotation;
  display: inline-block;
  position: relative;
}

.loader.small {
  border-width: 5px;
  width: 24px;
  height: 24px;
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  z-index: 0;
  direction: ltr;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.description-field {
  background: #d1ffa4;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  margin-top: -10px;
  padding: 10px;
  display: inline-flex;
  overflow: hidden;
}

.description-field-text {
  text-wrap: wrap;
  max-width: 275px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
}

.description-field-icon {
  fill: green;
}

.beneficiary {
  flex-direction: column;
  display: flex;
  width: 325px !important;
}

.beneficiary-header {
  justify-content: flex-start;
  align-items: center;
  gap: 176px;
  width: 100%;
  margin-bottom: 10px;
  display: inline-flex;
}

.beneficiary-header-text {
  color: #000;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.beneficiary-header-view-all {
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-family: Poppins;
  font-size: 10px;
  display: inline-flex;
}

.beneficiary-header-view-all:hover, .beneficiary-header-view-all:focus {
  background-color: #ebebeb;
}

.beneficiary-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
  width: 100%;
  height: 100%;
  display: inline-flex;
  overflow: auto clip;
}

.beneficiary-list::-webkit-scrollbar {
  display: none;
}

.beneficiary-item {
  cursor: pointer;
  border-radius: 2px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  width: 63px;
  padding: 3px;
  display: inline-flex;
}

.beneficiary-item.active .beneficiary-avatar-bg {
  background: var(--button-primary);
}

.beneficiary-item.active .beneficiary-title {
  color: var(--button-primary);
}

.beneficiary-avatar {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.beneficiary-avatar-bg {
  background: var(--darkdark-200);
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  display: flex;
}

.beneficiary-avatar-initials {
  color: #fff;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.beneficiary-title {
  text-align: center;
  color: var(--darkdark-200);
  word-wrap: anywhere;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.4px;
  display: -webkit-box;
  overflow: hidden;
}

.beneficiary-screen-header {
  justify-content: center;
  align-items: center;
  width: 375px;
  display: flex;
}

.beneficiary-screen-back {
  cursor: pointer;
  display: flex;
  position: absolute;
  left: calc(50vw - 187.5px);
}

.beneficiary-screen-title {
  color: #09132d;
  letter-spacing: .1px;
  word-wrap: break-word;
  padding: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
}

.beneficiary-screen-search {
  padding: 20px 0;
}

.beneficiary-screen-search input {
  color: #9c9c9c;
  letter-spacing: .28px;
  background: #ebebeb;
  border: none;
  border-radius: 10px;
  outline: none;
  width: 100%;
  padding: 10px 20px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}

.beneficiary-screen-item {
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  width: calc(100% + 65px);
  height: 100%;
  padding: 5px 0;
  display: inline-flex;
  overflow: hidden;
}

.beneficiary-screen-container {
  width: 100%;
  transition: transform .8s;
  display: flex;
}

.beneficiary-screen-container.translate {
  transform: translateX(-65px);
}

.beneficiary-screen-item-inner {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  width: calc(100% - 65px);
  display: inline-flex;
}

.beneficiary-screen-item-inner .option {
  align-self: center;
  margin-left: auto;
  padding: 10px;
  font-weight: bolder;
  transform: rotateZ(90deg);
}

.beneficiary-screen-container .delete {
  justify-content: center;
  align-items: center;
  display: flex;
}

.beneficiary-screen-container .delete .delete-icon-bg {
  background-color: red;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 54px;
  display: flex;
}

.beneficiary-screen-item-details {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  display: inline-flex;
}

.beneficiary-screen-item-name {
  color: #000;
  letter-spacing: .3px;
  word-wrap: break-word;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
}

.beneficiary-screen-item-highlight {
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  margin-bottom: 10px;
  display: inline-flex;
}

.beneficiary-screen-item-key {
  color: #454545;
  letter-spacing: .24px;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.beneficiary-screen-item-value {
  color: #9c9c9c;
  letter-spacing: .2px;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
}

.beneficiary-screen-item-divider {
  border: 1px solid #d5d5d5;
  width: 342px;
  height: 0;
}

.beneficiary-screen-list {
  height: calc(100vh - 159px);
  overflow: auto;
}

.beneficiary-avatar-bg.blue {
  background: #09132d;
}

.beneficiary-avatar-bg.green {
  background: #092d0f;
}

.beneficiary-avatar-bg.red {
  background: #2d0914;
}

.beneficiary-info-wrapper {
  padding: 20px 0;
}

.beneficiary-info-item {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  height: 100%;
  padding: 5px 0;
  display: inline-flex;
}

.beneficiary-info-item-inner {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 10px;
  display: inline-flex;
  border: 1px solid #b2b2b2 !important;
  border-radius: 11px !important;
}

.beneficiary-info-item-details {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1px;
  width: 100%;
  display: inline-flex;
}

.beneficiary-info-item-name {
  color: #000;
  letter-spacing: .3px;
  word-wrap: break-word;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  display: flex;
}

.beneficiary-info-item-cancel {
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 10px;
  display: inline-flex;
}

.beneficiary-info-item-cancel:hover {
  background-color: #ebebeb;
}

.beneficiary-info-item-highlight {
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 5px 0;
  display: inline-flex;
}

.beneficiary-info-item-key {
  color: #454545;
  letter-spacing: .24px;
  word-wrap: break-word;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
}

.beneficiary-info-item-value {
  color: #000;
  letter-spacing: .2px;
  word-wrap: break-word;
  float: right;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
}

.beneficiary-info-item-divider {
  border: 1px solid #d5d5d5;
  width: 80%;
  height: 0;
}

.authorization {
  text-align: center;
  flex-direction: column;
  gap: 40px;
  max-width: 320px;
  margin-bottom: 30px;
  font-family: Poppins;
  display: flex;
}

.authorization .header {
  font-size: 16px;
  font-weight: 500;
}

.authorization .message {
  font-size: 14px;
  line-height: 1.3;
}

.authorization strong {
  font-weight: 600;
}

.authorization .check-icon {
  margin-bottom: 7px;
}

.authorization .agree {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.authorization .agree input {
  accent-color: var(--button-primary);
  width: 20px;
  height: 20px;
}

.authorization-cover .action {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.modal {
  z-index: 999;
  width: 100%;
  height: 100%;
  position: absolute;
}

.modal .backdrop {
  background: #0000001a;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
}

.modal .content {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013, 0 8px 8px #00000013, 0 16px 16px #00000013;
}

.modal .content .body {
  padding: 28px;
}

.fin-literacy .icon .check-icon {
  box-sizing: content-box;
  background: #fff;
  border-radius: 50%;
  padding: 10px;
  position: relative;
  top: 60px;
}

.fin-literacy .header-bg {
  background: #09132d;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100px;
  margin: -28px -28px 60px;
}

.fin-literacy {
  text-align: center;
  max-width: 320px;
  margin-bottom: 30px;
  font-family: Poppins;
}

.fin-literacy .message {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.fin-literacy .message .header {
  font-size: 16px;
  font-weight: 500;
}

.fin-literacy .message .text {
  font-size: 14px;
  line-height: 1.3;
}

.fin-literacy strong {
  font-weight: 600;
}

.fin-literacy-cover .action {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.success-modal-cover {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.success-modal .icon .check-icon {
  background: #fff;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 10px;
}

.success-modal .header-bg {
  margin-bottom: 20px;
}

.success-modal {
  text-align: center;
  max-width: 320px;
  margin-bottom: 30px;
  font-family: Poppins;
}

.success-modal .message {
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.success-modal .message .header {
  font-size: 16px;
  font-weight: 500;
}

.success-modal .message .text {
  font-size: 14px;
  line-height: 1.3;
}

.success-modal strong {
  font-weight: 600;
}

.success-modal-cover .action {
  text-align: center;
  background: #eff9e5;
  border-radius: 10px;
  padding: 20px;
}

.success-modal-cover .action .input-field-instance {
  width: unset !important;
}

.success-modal-cover .action .text {
  padding: 5px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
}

.success-modal-cover .action .beneficiary-name {
  background: #eff9e5;
  border: 1px solid #aaa;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  overflow: hidden;
  gap: 0 !important;
}

.success-modal-cover .action .beneficiary-name .save-btn {
  cursor: pointer;
  letter-spacing: 1px;
  padding: 10px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: bold;
}

.success-modal-cover .action .beneficiary-name input {
  background: #eff9e5;
}

.error-field {
  background-color: var(--foundation-failedf75);
  font-family: var(--body-1-font-family);
  font-weight: var(--body-1-font-weight);
  font-size: var(--body-1-font-size);
  color: var(--darkdark-500);
  border-radius: 5px;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: -10px;
  padding: 10px;
  display: flex;
}

.error-field-text {
  text-wrap: wrap;
  max-width: 275px;
  font-family: Poppins, Helvetica;
  font-size: 12px;
  font-weight: 400;
}

.error-field-icon {
  fill: var(--foundation-failedf700);
}
/*# sourceMappingURL=index.615b9988.css.map */
